import axios from 'axios';

export class AnalyticsService {
  constructor(userId) {
    this.user_id = userId;
    this.event_type = '';
    this.name = '';
    this.product_id = 4;
    this.event_data = {
      page_name: '',
      page_section: '',
    };
  }

  async sendEventData() {
    try {
      const analyticsEnv=process.env.GATSBY_ANALYTICS_ENV;
      const analyticsToken=process.env.GATSBY_ANALYTICS_TOKEN;
      const response = await axios.post(analyticsEnv, {
        event_type: this.event_type,
        name: this.name,
        product_id: this.product_id,
        user_id: this.user_id,
        event_data: this.event_data,
      }, {
        headers: {
          'Authorization': analyticsToken,
          'Content-Type': 'application/json',
        },
      });

      console.log('Réponse : ', response.data);
    } catch (error) {
      console.error('Erreur : ', error.message);
    }
  }

  setEventType(eventType) {
    this.event_type = eventType;
  }

  setClickableEventData(name, event_data) {
    this.setEventType("click");
    this.name = name;
    if (event_data) {
      this.event_data = event_data;
    }
  }

  setInputEventData(name, event_data) {
    this.setEventType("input");
    this.name = name;
    if (event_data) {
      this.event_data = event_data;
    }
  }

  createEventData(page_name, page_section, data = null) {
    this.event_data.page_name = page_name;
    this.event_data.page_section = page_section;
    if (data !== null) {
      this.event_data.data = data;
    }
  }
}
