import { AnalyticsService } from "./analytics.api";

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

let uuid = generateUUID();
const analyticsService = new AnalyticsService(uuid);

export function sendAnalyticsInput(page_section, name, data = null) {
    if (analyticsService !== undefined) {
        analyticsService.createEventData('search_engine', page_section, data)
        analyticsService.setInputEventData(name)
        analyticsService.sendEventData()
    } else {
        console.error("No analytics");
    }
}

export function sendAnalyticsClick(page_section, name, data = null) {
    if (analyticsService !== undefined) {
      analyticsService.createEventData('search_engine', page_section, data);
      analyticsService.setClickableEventData(name);
      analyticsService.sendEventData();
    } else {
      console.error("No analytics");
    }
  };