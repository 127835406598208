import { OfficeBuildingIcon, PaperClipIcon } from "@heroicons/react/solid";
import React from "react";
import { classNames, getEffectif } from "../../helpers";
import { sendAnalyticsClick } from "../../analytics/analytics";


export default function CompanyCard({ company, withPadding = false }) {

  const getAnalyticsVignette = (type) => {
    const eventType = "click_" + type;
    sendAnalyticsClick("Vignette_list", eventType);
  }

  const getAnalyticsCertificate = (data) => {
    const eventType = "click_certificate";
      sendAnalyticsClick("Vignette_list", eventType, data)
  };

  return (
    <div
      className={classNames(
        withPadding ? "mt-5" : null,
        "bg-white shadow overflow-hidden sm:rounded-lg"
      )}
    >
      <div
        className={classNames(
          withPadding ? "px-4 py-5 sm:px-6" : "px-1 py-2 sm:px-3"
        )}
      >
        <h3 className="text-xl leading-6 font-bold text-qe-light-blue flex items-center">
          {company.name}
          <OfficeBuildingIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </h3>

        <div className="mt-1 max-w-2xl text-sm text-gray-500" onClick={() => getAnalyticsVignette('address')}>
          <p>
            {company.address_line_1}
          </p>
          <p>
            {company.address_line_2}
          </p>
          <p>
            {company.zip} {company.city}
          </p>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">SIREN</dt>
            <dd className="mt-1 text-sm text-gray-900">{company.siren}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">SIRET</dt>
            <dd className="mt-1 text-sm text-gray-900">{company.siret}</dd>
          </div>
          <div className="sm:col-span-1" onClick={() => getAnalyticsVignette('phone')}>
            <dt className="text-sm font-medium text-gray-500">Téléphone</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {company.phone}
            </dd>
          </div>
          <div className="sm:col-span-1" onClick={() => getAnalyticsVignette('mail')}>
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a href={`mailto:${company.email}`}>
                {company.email}
              </a>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Qualifications
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul role="list" className="divide-y divide-gray-200">
                {company._affaires.map((affaire, index) => (
                  <li
                    key={index}
                    className="relative bg-white py-5 px-4 hover:bg-gray-50"
                    onClick={() => {
                      const data = {
                        company_name: company.name, 
                        SIRET: company.siret,
                        certificate_URL: affaire.certificates[0].url,
                        certificate_name: affaire.qualification
                      };
                        getAnalyticsCertificate(data, affaire);
                    }}
                  >
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {affaire.qualification}
                        </p>
                      </div>
                    </div>

                    {affaire.indices.length > 0 && (
                      <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">
                          Indice(s) : {affaire.indices.join(", ")}
                        </p>
                      </div>
                    )}

                    {affaire.classes.length > 0 && (
                      <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">
                          Classe(s):{" "}
                          {affaire.classes
                            .map((clss) => {
                              return getEffectif(clss);
                            })
                            .join(", ")}
                        </p>
                      </div>
                    )}

                    {affaire.mentions.length > 0 && (
                      <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">
                          Mention(s) : {affaire.mentions.join(", ")}
                        </p>
                      </div>
                    )}

                    {affaire.domains.length > 0 && (
                      <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">
                          Domaine(s) : {affaire.domains.join(", ")}
                        </p>
                      </div>
                    )}
                    
                    {affaire.certificates.length > 0 && (
                      <div className="mt-1">
                        <ul
                          role="list"
                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                        >
                          {affaire.certificates.map((item) => (
                            <li
                              key={item.name}
                              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                            >
                              <div className="w-0 flex-1 flex items-center">
                                <PaperClipIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span 
                                className="ml-2 flex-1 w-0 truncate">
                                {item.name}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                {item.onGoing ? (
                                  <span>Certificat en cours</span>
                                ) : (
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-medium text-qe-orange hover:underline"
                                  >
                                    Télécharger
                                  </a>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
